import React, { useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Subheading from "./../components/Subheading"
import Pagination from "./../components/Pagination"
import ResourceCards from "./../components/Cards/ResourceList"

export default ({
  pageContext: {
    resources: { nodes: resources },
    resourcesOldest: { nodes: resourcesOldest },
    currentPage,
    limit,
    numPages,
    skip,
  },
  location: { state },
}) => {
  const [mode, setMode] = useState(state ? state.sort : "newest")

  const getItems = mode => {
    let items = {}
    switch (mode) {
      case "oldest":
        items = resourcesOldest
        break
      case "newest":
      default:
        items = resources
        break
    }
    return items
  }

  const maxLimit = 9
  const currentPageLimit = maxLimit * (currentPage - 1)
  const [currentList, addToList] = useState(
    getItems(mode)
      ? [...getItems(mode).slice(currentPageLimit, currentPageLimit + maxLimit)]
      : []
  )

  return (
    <Layout
      pageInfo={{ pageName: `Resources - Page ${currentPage} of ${numPages}` }}
    >
      <SEO title={`Resources - Page ${currentPage} of ${numPages}`} />
      <Subheading title="Resources">
        Explore these fire safety resources to learn more about how to help keep
        your family fire safe.
      </Subheading>
      <Pagination
        sortMode={state ? state.sort : "newest"}
        onClickSort={mode => {
          setMode(mode)
          if (currentPageLimit + maxLimit >= getItems(mode).length) {
            addToList([...getItems(mode).slice(currentPageLimit)])
          } else {
            addToList([
              ...getItems(mode).slice(
                currentPageLimit,
                currentPageLimit + maxLimit
              ),
            ])
          }
        }}
        urlPrefix={`/resources`}
        currentPage={currentPage}
        numPages={numPages}
      />
      <ResourceCards items={currentList} />
      <Pagination
        urlPrefix={`/resources`}
        sorting={false}
        loadmore={true}
        currentPage={currentPage}
        numPages={numPages}
      />
    </Layout>
  )
}
