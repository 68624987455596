import React from "react"
import ResourceCard from "./ResourceCard"
import { Container, Row, Col } from "react-bootstrap"

export default ({ items }) => {
  return (
    <Container className={"px-0 resource-cards pt-5"}>
      <Row>
        {items &&
          items.map((item, i) => (
            <Col
              md={6}
              lg={3}
              key={"resource-col-" + i}
              className={"pb-4 d-flex"}
              data-sal="slide-up"
              data-sal-duration="800"
              data-sal-delay="1200"
              data-sal-easing="ease"
            >
              <ResourceCard key={"resource-card-" + i} {...item} />
            </Col>
          ))}
      </Row>
    </Container>
  )
}
